import Link from "next/link"
import Image from "next/image"
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import HeroGraphicLight from '../../public/landing.png'
import { Inter } from 'next/font/google'
import FaqBlock from "../../components/faqblock";
import styles from '../styles/Home.module.css'
import Head from "next/head";


const inter = Inter({ subsets: ['latin'] })

export default function LandingPage() {
  return (
    <>
    <Head>
      <title>StyleSense.io - AI Face Analyzer & Personalized Makeup Guidance</title>
      <link
          rel="canonical"
          href="https://stylesense.io"
          key="canonical"
        />      
      <meta
        name="description"
        content="Use our AI tools to identify your face shape, eye shape, and more. Simply take or upload a selfie to get started. Then, use our makeup guides tailored to your facial features to learn how to best accentuate your beauty."
        key="desc"
      />
    </Head>      
    <div className="flex min-h-screen flex-col">
      {/* Navigation */}
      {/* <nav className="fixed top-0 z-50 w-full border-b bg-white/80 backdrop-blur-sm">
        <div className="container flex h-16 items-center justify-between px-4">
          <div className="flex items-center gap-2">
            <div className="h-8 w-8 rounded-full bg-gradient-to-br from-purple-500 to-pink-500"></div>
            <span className="text-xl font-semibold">FaceAI</span>
          </div>
          <div className="flex items-center gap-4">
            <Link href="#" className="text-sm font-medium text-muted-foreground hover:text-foreground">
              Help
            </Link>
            <button variant="outline" className="font-medium">
              Try it free for 14 days
            </button>
            <button className="bg-pink-500 font-medium hover:bg-pink-600">Sign in</button>
          </div>
        </div>
      </nav> */}
      <Navbar />
      {/* Hero Section */}
      <section className="md:px-8 2xl:p-0 w-full mx-auto py-4">
        <div className="container md:px-2 max-w-7xl mx-auto">
          <div className="grid gap-8 lg:grid-cols-2 items-center bg-white/80 rounded-3xl px-4 sm:px-8 py-4 flex-col-reverse">
            <div className="flex flex-col justify-center lg:p-8 bg-white border-20 border-white rounded-3xl order-2 lg:order-1">
              <h1 className="font-serif text-3xl sm:text-4xl font-bold tracking-tight sm:text-5xl md:text-6xl">
                Forget the Guesswork.
              </h1>
              <p className="mt-6 text-base sm:text-lg md:text-xl text-muted-foreground">
                Most &quot;face shape&quot; tools shove you into a single, arbitrary box—square, round, oval. But real faces don&apos;t work like that.
                <br/> <br/>
                Our algorithm actually analyzes your features. Sharp cheekbones? Narrow forehead? Strong jaw? Instead of forcing you into one shape, we identify your standout traits and show you how they really combine.
              </p>
              <p className="mt-6 text-base sm:text-lg md:text-xl">
                Your face is{" "}
                <span className="relative inline-block">
                  <span className="absolute -inset-1 -skew-y-3 bg-pink-100"></span>
                  <span className="relative">unique.</span>
                </span>
                . It&apos;s time your makeup routine reflected that.
              </p>
              <div className="mt-8 flex flex-col md:flex-row gap-4">
                <Link
                  href="/face_analyzer"
                  className={`flex items-center justify-center text-center py-3 border border-white/20 text-base font-medium rounded-md md:rounded-full shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-200 w-full md:w-auto md:mr-3 bg-rainbow hover:bg-hoverrainbow dark:bg-rainbowdark dark:hover:bg-hoverrainbowdark px-4 sm:px-6 text-sm sm:text-base`}
                >
                  Analyze My Face{" "}
                  <span className={`${inter.className} ml-2`}>-&gt;</span>
                </Link>
                <Link
                  href="/makeup_recommendations/face_contour"
                  className={`flex items-center justify-center text-center py-3 border border-white/20 text-base font-medium rounded-md md:rounded-full shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-200 w-full md:w-auto bg-rainbowdark hover:bg-hoverrainbowdark dark:bg-rainbow dark:hover:bg-hoverrainbow px-4 sm:px-6 text-sm sm:text-base`}
                >
                  Personalize My Makeup{" "}
                  <span className={`${inter.className} ml-2`}>-&gt;</span>
                </Link>
              </div>
              <div>
              </div>
            </div>
            <div className="relative mx-auto max-w-[600px] lg:max-w-full pr-0 sm:pr-8 order-1 lg:order-2">
              <div className="absolute -top-32 -right-32 h-[700px] w-[700px] rounded-full bg-gradient-to-br from-purple-200 to-pink-200 opacity-40 blur-3xl"></div>
              <div className="relative aspect-[4/3] overflow-hidden rounded-3xl border-8 border-white shadow-2xl">
                <Image
                  className="w-full dark:hidden object-cover"
                  src={HeroGraphicLight}
                  alt="StyleSense.io - AI Face Analysis and Personalized Makeup Recommendations"
                  width={6900}
                  height={5100}
                  priority={true}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section data-v-7fa77991="" class="py-12 sm:py-16 lg:py-16 xl:py-16">
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div class="max-w-3xl mx-auto text-center mb-4">
              <h2 class="mt-6 text-3xl font-medium tracking-tight text-gray-900 dark:text-gray-50 sm:text-4xl lg:mt-8 lg:text-5xl">
                Frequently asked questions
              </h2>{" "}
              <p class="mt-4 mb-8 text-base font-normal leading-7 text-gray-600 dark:text-gray-200 lg:text-lg lg:mt-6 lg:leading-8">
                We&apos;ve compiled a list of the most common questions we get asked.
                If you have any other questions, please feel free to reach out
                to us in the{" "}
                <Link href="/help" className="underline">
                  Help Center
                </Link>
                .
              </p>
            </div>{" "}
            <div className="dark:bg-white/50 dark:p-3 dark:rounded">
              <FaqBlock />
            </div>
          </div>
        </div>
      </section>        
      <div className={`${styles.grid} mx-auto my-12 px-4`}>
        <Link href="/face_analyzer" className={styles.card}>
          <h2 className={inter.className}>
            Face Analyzer <span>-&gt;</span>
          </h2>
          <p className={inter.className}>
            Identify your face shape and other facial features.
          </p>
        </Link>

        <Link href="/makeup_recommendations/face_contour" className={styles.card}>
          <h2 className={inter.className}>
            {/* <div className="badge badge-neutral text-white badge-md mx-2 move-right">
              NEW
            </div> */}
            Makeup Recommendations <span>-&gt;</span>
          </h2>
          <p className={inter.className}>
            Learn how to best accentuate your facial features.
          </p>
        </Link>

        <Link href="/skintone_analyzer" className={styles.card}>
          <h2 className={inter.className}>
            Skintone Analyzer <span>-&gt;</span>
          </h2>
          <p className={inter.className}>In beta</p>
        </Link>
      </div>

      <Footer />      
    </div>    
    </>
  )
}